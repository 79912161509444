<template>
  <b-container class="mt-3" fluid>
    <h2>Solicitudes de cancelación</h2>

    <div class="d-flex align-items-center mt-3">
      <div class="mx-2">
        <label for="codigo">Código</label>
        <b-input placeholder="código" v-model="codigo"></b-input>
      </div>

      <div class="mx-2 w-25">
        <label for="cliente">Cliente</label>
        <b-overlay :show="cargando">
          <select-cliente class="p-0 m-0" @select-cliente="value => clienteSelected = value" />
        </b-overlay>
      </div>

      <div class="mx-2 w-25">
        <label for="proveedor">Proveedor</label>
        <b-overlay :show="cargando">
          <multiselect v-model="proveedorSelected" deselect-label="Can't remove this value"
            track-by="name" label="name" placeholder="Seleccione un proveedor." :multiple="true"
            :options="proveedorOptions" :searchable="true" :allow-empty="true">
            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
          </multiselect>
        </b-overlay>
      </div>

      <b-button variant="primary" class="mx-2 align-self-end" style="background-color: #355070;"
        @click="getCancelaciones" :disabled="cargando">
        <b-spinner small v-if="cargando"></b-spinner>
        Buscar
      </b-button>
    </div>

    <b-overlay :show="cargando" class="px-2">
      <b-table-simple small hover bordered sticky-header="85vh">
        <b-thead>
          <b-tr>
            <b-th>Codigo</b-th>
            <b-th>Cliente</b-th>
            <b-th>Fecha Cancelación</b-th>
            <b-th>Proveedor</b-th>
            <b-th>Comentarios</b-th>
            <b-th>Autorizado</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(cancelacion, index) of cancelaciones" :key="index" style="cursor: pointer;"
            @click="cancelar(cancelacion)">
            <b-td>{{ cancelacion.Pedido.codigo }}</b-td>
            <b-td>{{ cancelacion.Pedido.cliente_usuario.razon_social }}</b-td>
            <b-td>{{ parseDate(cancelacion.FechaCreacion) }}</b-td>
            <b-td>{{ cancelacion.Pedido.proveedor_proveedore.nombre }}</b-td>
            <b-td>{{ cancelacion.Comentarios }}</b-td>
            <b-td>{{ cancelacion.Autorizado ? 'Sí' : 'No' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-overlay>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from 'moment';
import Swal from 'sweetalert2';
import notify from '../../../utils/notification';
import SelectCliente from '../../../components/base/_BaseSelectCliente.vue';
import { axiosInstance } from '../../../utils/axiosInstance';
export default {
  components: {
    SelectCliente,
    Multiselect
  },
  data: () => {
    return {
      proveedorSelected: null,
      proveedorOptions: [],
      clienteSelected: null,
      codigo: '',
      cargando: false,
      cancelaciones: []
    }
  },
  methods: {
    async getProveedoresCliente() {
      this.cargando = true;
      this.proveedorOptions = [];

      if (this.clienteSelected) {
        try {
          const { data } = await axiosInstance.get('proveedores/cliente', {
            params: { usuario_id: this.clienteSelected }
          });

          data.forEach(usuario_proveedor => {
            this.proveedorOptions.push({
              name: usuario_proveedor.proveedor.nombre,
              value: usuario_proveedor.proveedor_id
            });
          });
        } catch (error) {
          this.makeToast('Consultar proveedores', 'Hubo un error al consultar a los proveedores', 'danger', 3000);
        }
      }
      else {
        try {
          const { data } = await axiosInstance.get('proveedores/obtener-proveedores');

          data.proveedores.forEach(proveedor => {
            this.proveedorOptions.push({
              value: proveedor.proveedor_id,
              name: proveedor.nombre
            });
          });
        } catch (error) {
          this.makeToast('Consultar proveedores', 'Hubo un error al consultar a los proveedores', 'danger', 3000);
        }
      }

      this.cargando = false;
    },
    async getCancelaciones() {
      this.cargando = true;
      try {
        const result = await axiosInstance.get('pedidos/cancelacion', {

          params: {
            cliente: this.clienteSelected,
            proveedores: this.proveedorSelected?.map(x => x.value),
            codigo: this.codigo
          }
        });

        this.cancelaciones = result.data;
      } catch (error) {
        notify('error', 'Solicitudes', error.response.data.msg || 'Ocurrió un error al obtener las solicitudes de cancelación.');
      } finally {
        this.cargando = false;
      }
    },
    parseDate(date) {
      return moment(date.split('T')[0]).format('DD/MM/YYYY');
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async cancelar(cancelacion) {
      if (cancelacion.Autorizado) {
        notify('info', 'Cancelación', 'Cancelación ya autorizada.');
        return;
      }
      try {
        const result = await Swal.fire({
          text: `¿Desea cancelar el pedido ${cancelacion.Pedido.codigo}?`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#355070',
        });

        if (!result.isConfirmed) {
          return;
        }

        this.cargando = true;

        await axiosInstance.put(`pedidos/cancelacion/${cancelacion.CancelacionID}`);
        await this.getCancelaciones();
        notify('success', 'Cancelación', 'Pedido Cancelado');
      } catch (error) {
        notify('error', 'Cancelación', error.response.data.msg || 'Ocurrió un error en el servidor');
      } finally {
        this.cargando = false;
      }
    }
  },
  mounted() {
    this.getProveedoresCliente();
  },
  watch: {
    clienteSelected() {
      this.getProveedoresCliente();
    },
  },
}
</script>